<template>
  <div id="app" class="container">
    <div style="display: block; width: 100%; text-align: center;"><img style="width:100%;" alt="BeamNG.tech logo" src="./assets/logo.png"></div>
    <hr />
    <Registration />
  </div>
</template>

<script>
import Registration from './components/Registration.vue'

export default {
  name: 'App',
  components: {
    Registration
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

div.container {
  max-width: 768px;
}
</style>
