import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from './App.vue';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

axios.defaults.baseURL = process.env.VUE_APP_BEAMNG_API_BASE_URL || "https://api.beamng.com";
Vue.use(VueAxios, axios);
Vue.use(VueReCaptcha, { siteKey: '6Lfm7M8ZAAAAAKcgqNw8Dc7tuBR9DEhhgkqx0mmp' });
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
